import Axios from "axios";
const prescriocionService = {};
const token = localStorage.getItem("token");
prescriocionService.crearPrescripcion = async (data) => {
  try {
    return await Axios.post(
      "https://pres.kagencia.com/api/prescripcion",
      // "https://pres.kagencia.com/api/prescripcion",
      data,
      {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
          Authorization: "Bearer " + token,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  } catch (e) {
    return e;
  }
};
prescriocionService.getPrescripcionQr = async (tokenqr, id) => {
  try {
    return await Axios.post(
      `https://pres.kagencia.com/api/prescripcion/${id}`,
      // `https://pres.kagencia.com/api/prescripcion/${id}`,
      { tokenqr },
      {
        headers: {
          Authorization: tokenqr,
        },
      }
    ).catch((err) => {
      return err.response;
    });
  } catch (error) {
    return error;
  }
};
export { prescriocionService };
