import Axios from "axios";
import jwt from "jsonwebtoken";
// "https://prescripciones.puntossuspensivos.com.co/api/usuarios/login",

const sessionServices = {};
const token = jwt.decode(localStorage.getItem("token"));
sessionServices.login = async (cedula) => {
  try {
    const data = {
      cedula,
    };
    return await Axios.post(
      "https://pres.kagencia.com/api/usuarios/login",
      data
    ).catch((err) => {
      return err.response;
    });
  } catch (e) {
    return e;
  }
};
sessionServices.isLogged = () => {
  if (localStorage.getItem("token")) {
    return true;
  }
};

sessionServices.datosToken = () => {
  return token;
};
export { sessionServices };
