import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { sessionServices } from "../../Helpers/sessionsService";
import "react-toastify/dist/ReactToastify.css";
import "../Login/styles.css";
import PopUpGenerico from "../PopUpGenerico/PopUpGenerico";

export default function Logins(props) {
  const [popAuth, setPopAuth] = useState(false);
  useEffect(() => {
    if (props.match.params.id) {
      setPopAuth(true);

      function addToken(n) {
        if (n < 0) return;
        localStorage.setItem("token", props.match.params.id);
        console.log(localStorage.getItem("token"));
        if (localStorage.getItem("token")) {
          window.location.href = "/prescripcion";
        } else {
          addToken(n - 1);
        }
      }
      addToken(10);
    }
    // eslint-disable-next-line
  }, []);
  const [cedula, setCedula] = useState("");
  const onSubmitForm = async (e) => {
    e.preventDefault();
    const res = await sessionServices.login(cedula);
    if (res && res.status === 200) {
      localStorage.setItem("token", res.data.token);
      window.location.href = "/prescripcion";
    } else {
      toast.error(res.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      setCedula("");
      document.getElementById("cedula").value = "";
    }
  };
  return (
    <div className="container-login">
      <p>
        Bienvenido a prescripciones
        <br /> odontológicas Colgate,
        <br /> ingrese su número de cédula.
      </p>
      <form onSubmit={onSubmitForm} className="form-login">
        <input
          type="text"
          name="cedula"
          id="cedula"
          placeholder="Ingrese su cedula"
          onChange={(e) => {
            setCedula(e.target.value);
          }}
        />
        <input type="submit" value="Iniciar Sesión" />
      </form>
      {popAuth ? <PopUpGenerico /> : null}
      <ToastContainer />
    </div>
  );
}
