import React from 'react'

export default function Logout() {
    const logout = () => {
        localStorage.removeItem('token');
        window.location.href = '/login';
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button className="btn-logout" onClick={logout}>Cerrar Sesión</button>
        </div>
    )
}
