import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Logout from "./Logout";
import {sessionServices} from "../Helpers/sessionsService";
import Prescripcion from './Prescripciones/Prescripcion'
import Logins from './Login/Logins';
import NoMatch from './NoMatch';
import LoginPrescripcion from './VerPrescripcion/LoginPrescripcion';

function Routes(props) {
    const RouteSesions = (props) =>
        sessionServices.isLogged() ? <Redirect to="/prescripcion"/> : <Route {...props} />;
    const SecureRoute = (props) =>
        sessionServices.isLogged() ? <Route {...props} /> : <Redirect to="/login"/>;
    return (
        <Switch>
            <Route path={"/login/:id"} exact component={Logins}/>
            <SecureRoute path="/" exact component={Prescripcion}/>
            <SecureRoute path="/prescripcion" exact component={Prescripcion}/>
            <SecureRoute path="/logout" component={Logout}/>
            <RouteSesions path="/login" exact component={Logins}/>
            <Route path="/prescripcion/:id" component={LoginPrescripcion}/>
            <Route component={NoMatch}/>
        </Switch>
    );
}

export default Routes;