import React from 'react';
import './styles.css'
import Spinner from '../../assets/cargando.svg'

function PopUpGenerico(props) {

    return (
        <div className={"container-popup"}>
            <div className="contenedor-componente">
                <h1>Te estamos redirigiendo...</h1>
                <div className="img">
                    <img src={Spinner} alt=""/>
                </div>
            </div>
        </div>
    );
}

export default PopUpGenerico;