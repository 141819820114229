import React from "react";
import './App.css';
import ColgateLogo from './assets/Colgate_logo.svg'
import Logins from './components/Login/Logins';
import {sessionServices} from './Helpers/sessionsService';
import Routes from './components/Routes';
import {
    BrowserRouter as Router
} from 'react-router-dom'

function App() {
    return (
        <Router>
            <div className="App">
                <header>
                    <div className="logo">
                        <img src={ColgateLogo} alt="Colgate Logo"/>
                    </div>
                    <div className="Titulo-App">
                        <h2>PRESCRIPCIONES</h2>
                        <h2>ODONTOLÓGICAS</h2>
                    </div>
                </header>
                <div className="container">
                    {sessionServices.isLogged ? <Routes/> : <Logins/>}
                </div>
            </div>
        </Router>
    );
}

export default App;
