import React, { useEffect, useState } from "react";
import { prescriocionService } from "../../Helpers/prescripcionServices";
import { productosServices } from "../../Helpers/productosServices";
import { sessionServices } from "../../Helpers/sessionsService";
import "./styles.css";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import "moment/locale/es-mx";
import Logout from "../Logout";
import { saveAs } from "file-saver";

export default function Prescripcion(props) {
  const [productos, setProductos] = useState([]);
  const [datosDoctor, setDatosDoctor] = useState(null);
  const [productosSeleccionados, setProductosSeleccionados] = useState([]);
  const [datos, setDatos] = useState({
    firma: "",
    logo: "",
    dia: moment().format("DD"),
    mes: moment().format("MM"),
    year: moment().format("YY"),
    paciente: "",
    cedula: "",
    doctor: "",
    telefono: "",
    productos: [],
    descripcion: "",
    codigoColgate: sessionServices.datosToken().codigoColgate,
  });

  useEffect(() => {
    getData();
    getDataDoctor();
    // eslint-disable-next-line
  }, []);
  const getData = async () => {
    const res = await productosServices.getProductos();
    if (res && res.status === 200) {
      setProductos(res.data);
    }
  };
  const getDataDoctor = () => {
    const res = sessionServices.datosToken();
    setDatos({
      ...datos,
      doctor: res.nombre,
    });
    setDatosDoctor(res);
  };
  const handleInputChange = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmitForm = async (e) => {
    e.preventDefault();
    const res = await prescriocionService.crearPrescripcion(datos);
    if (res) {
      const blob = new Blob([res.data], { type: "application/pdf" });
      saveAs(blob, "prescripcion.pdf");
    }
    if (res.status === 200) {
      setProductosSeleccionados([]);
      cleanDatos();
      document.getElementById("form_prescripciones").reset();
      let textArea = document.querySelector("textarea");
      textArea.value = "";
      toast.success("Se ha generado tu prescripción", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      toast.error("No se ha podido generar tu suscripción", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };
  const deleteProductFromSeleccionado = (id) => {
    const newData = productosSeleccionados.filter(
      (producto) => producto._id !== id
    );
    setProductosSeleccionados(newData);
  };
  const cleanDatos = () => {
    setDatos({
      dia: moment().format("DD"),
      mes: moment().format("MM"),
      year: moment().format("YY"),
      cedula: "",
      doctor: datosDoctor.nombre,
      paciente: "",
      productos: [],
      telefono: "",
      firma: "",
      logo: "",
      descripcion: "",
      codigoColgate: sessionServices.datosToken().codigoColgate,
    });
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) {
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
      }
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  return (
    <div className="container-prescripciones">
      <Logout />
      <h2>Seleccione los productos</h2>
      <form onSubmit={onSubmitForm} id="form_prescripciones">
        <div className="contenedor-productos">
          {productos ? (
            productos.map((producto) => (
              <div className="producto" key={producto._id}>
                <label htmlFor={producto._id}>
                  <img
                    src={`https://pres.kagencia.com/api/images/${producto.imagen}`}
                    alt={producto.descripcion}
                    onClick={() => {
                      window.location.href = "#contenedor-seleccionado";
                    }}
                  />
                </label>
                <p>{producto.descripcion}</p>
                <div className="checkbox" style={{ display: "none" }}>
                  <input
                    type="checkbox"
                    name="producto"
                    id={producto._id}
                    onChange={(e) => {
                      if (productosSeleccionados.indexOf(producto) === -1) {
                        setProductosSeleccionados([
                          ...productosSeleccionados,
                          producto,
                        ]);
                        setDatos({
                          ...datos,
                          productos: [...datos.productos, producto],
                        });
                      }
                    }}
                  />
                </div>
              </div>
            ))
          ) : (
            <h1>Loading...</h1>
          )}
        </div>

        <h2>Prescripción de Producto</h2>
        <div className="container-info-prescripcion">
          <div className="fecha">
            <h3>
              Fecha: <span style={{ color: "red" }}>*</span>
            </h3>
            <div className="recuadros-fecha">
              <input
                type="number"
                min="1"
                required
                placeholder="D"
                name="dia"
                maxLength="2"
                value={datos.dia}
                disabled
              />
              <input
                type="number"
                min="1"
                required
                placeholder="M"
                name="mes"
                maxLength="2"
                value={datos.mes}
                disabled
              />
              <input
                type="number"
                min="1"
                required
                placeholder="A"
                name="year"
                maxLength="2"
                value={datos.year}
                disabled
              />
            </div>
          </div>
          <div className="paciente">
            <h3>
              Paciente: <span style={{ color: "red" }}>*</span>
            </h3>
            <div className="recuadros-paciente">
              <input
                type="text"
                required
                placeholder=""
                name="paciente"
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </div>
          </div>
          <div className="paciente">
            <h3>
              Nro de Cédula: <span style={{ color: "red" }}>*</span>
            </h3>
            <div className="recuadros-paciente">
              <input
                type="number"
                min="1"
                pattern="[0-9]*"
                required
                placeholder=""
                name="cedula"
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </div>
          </div>
          <div
            className="contenedor-seleccionado"
            id="contenedor-seleccionado"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {productosSeleccionados
              ? productosSeleccionados.map((producto) => (
                  <div
                    className="producto-seleccionado"
                    key={producto._id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "20px",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src={`https://pres.kagencia.com/api/images/${producto.imagen}`}
                      style={{ width: "30%", height: "30%", flexShrink: "2" }}
                      alt={producto.descripcion}
                    />
                    <h1>{producto.descripcion}</h1>
                    <button
                      style={{
                        background: "var(--red)",
                        height: "30px",
                        border: "none",
                        padding: "10px",
                        marginLeft: "20px",
                        color: "white",
                        borderRadius: "20px",
                        textAlign: "center",
                        outline: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() =>
                        deleteProductFromSeleccionado(producto._id)
                      }
                    >
                      X
                    </button>
                  </div>
                ))
              : ""}
          </div>
          <div className="container-descripcion">
            <h3>Descripción</h3>
            <textarea
              name="descripcion"
              id="descripcion"
              cols="30"
              rows="10"
              onChange={(e) => {
                handleInputChange(e);
              }}
            />
          </div>
          <div className="firma-logo">
            <div className="Firma">
              <label htmlFor="firma">
                {datos.firma ? (
                  <img src={datos.firma} width="60%" alt="Firma medico" />
                ) : (
                  <p>Firma</p>
                )}
              </label>
            </div>
            <div className="Logo">
              <label htmlFor="logo">
                {datos.logo ? (
                  <img src={datos.logo} width="60%" alt="Logo doctor" />
                ) : (
                  <p>Logo</p>
                )}
              </label>
            </div>
          </div>
          <div className="inputs-file" style={{ display: "none" }}>
            <input
              type="file"
              id="firma"
              name={"firma"}
              accept="image/png, .jpeg, .jpg, image/gif"
              onChange={async (e) => {
                const file = e.target.files[0];
                const base64 = await convertToBase64(file);
                setDatos({
                  ...datos,
                  firma: base64,
                });
              }}
            />
            <input
              type="file"
              id="logo"
              name={"logo"}
              accept="image/png, .jpeg, .jpg, image/gif"
              onChange={async (e) => {
                const file = e.target.files[0];
                const base64 = await convertToBase64(file);
                setDatos({
                  ...datos,
                  logo: base64,
                });
              }}
            />
          </div>

          <div className="paciente">
            <h3>Dr(a).: </h3>
            <div className="recuadros-paciente">
              <p>{datosDoctor ? datosDoctor.nombre : ""}</p>
            </div>
          </div>
          <div className="paciente">
            <h3>
              Telefono: <span style={{ color: "red" }}>*</span>
            </h3>
            <div className="recuadros-paciente">
              <input
                type="number"
                min="1"
                pattern="[0-9]*"
                required
                name="telefono"
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </div>
          </div>
          <div className="contenedor-input">
            <input
              type="submit"
              className="generar-prescripcion"
              value="Generar Prescripción"
            />
          </div>
          <p style={{ textAlign: "center" }}>
            Encuentre el producto en: Drogas la Rebaja, Farmatodo, Droguerías
            Alemana, Cruz Verde, Farmacias Pasteur, Locatel
          </p>
        </div>
      </form>

      <ToastContainer />
    </div>
  );
}
