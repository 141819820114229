import Axios from 'axios'

const productosServices = {};
const token = localStorage.getItem('token')
productosServices.getProductos = async() => {
    try {
        return await Axios.get('https://pres.kagencia.com/api/productos', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).catch((err) => {
            return err.response;
        });
    } catch (e) {
        return e;
    }
}
export { productosServices };