import React, { useState } from "react";
import { prescriocionService } from "../../Helpers/prescripcionServices";
import "./styles.css";
import { ToastContainer, toast } from "react-toastify";
import "../Login/styles.css";

export default function LoginPrescripcion(props) {
  const [token, setToken] = useState("");
  const [infoPres, setInfoPres] = useState(null);
  const onSubmitForm = async (e) => {
    e.preventDefault();
    const res = await prescriocionService.getPrescripcionQr(
      token,
      props.match.params.id
    );
    if (token !== process.env.REACT_APP_API_TOKEN) {
      toast.error("Error, token no autorizado", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      if (res.status === 200) {
        setInfoPres(res.data);
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };
  return (
    <div className="container-ver-prescripcion">
      <h1>Por favor, ingrese el token que se le suministró</h1>
      <form onSubmit={onSubmitForm}>
        <input
          type="text"
          name="token"
          id="token"
          onChange={(e) => {
            setToken(e.target.value);
          }}
          disabled={!!infoPres}
        />
      </form>
      {infoPres ? (
        <div>
          <h2>Codigo Colgate:</h2> {infoPres.codigoColgate}
          <h2>Productos: </h2>
          <div className="productos">
            {infoPres.productos.map((producto) => (
              <div
                className="producto"
                style={{ display: "flex", alignItems: "center" }}
                key={producto._id}
              >
                <p style={{ marginLeft: "10px" }}>Codigo: {producto.codigo}</p>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
}
